const CustomSearchBox = ({
  _className,
  _onchangeFunc,
  value,
  placeHolderText,
}) => {
  console.log(value);
  return (
    <form noValidate action="" role="search" className={_className}>
      <input
        type="search"
        onChange={(event) => {
          _onchangeFunc(event);
        }}
        className="search-bar shadow-sm"
        placeholder={placeHolderText || "Search by village name"}
      />
    </form>
  );
};

export default CustomSearchBox;
