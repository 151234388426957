import React, { Component, useEffect, useState } from "react";
import firebase from "../../../firebase";
import ReactPlaceholder from "react-placeholder";
import { RectShape } from "react-placeholder/lib/placeholders";
import { Link } from "react-router-dom";
import { Alert } from "react-bootstrap";
import MyLayout from "../../Components/MyLayout/index";
import MyDesktop from "../../Components/MyComponents/MyDesktop";
import MyMobile from "../../Components/MyComponents/MyMobile";
import CustomSearchBox from "./CustomSearchBar";
import VillageCard from "./VillageCard";
import MenuSelect from "./MenuSelect";
import "react-placeholder/lib/reactPlaceholder.css";
import "./index.css";
import axios from "axios";
import { Box } from "@mui/material";
import VirtualWebBanner from "../../Assets/img/background/virtual_web_banner.png";
import VirtualMobBanner from "../../Assets/img/background/virtual_mob_banner.png";
import Typography from "@mui/material/Typography";
import VirtualTourImage from "../../Assets/img/background/virtual_tour_image.png";
import MobVirtualTourImage from "../../Assets/img/background/virtual_tour_mob.png";
import PageContainer from "../../Components/Container";

const awesomePlaceholder = (
  <div className="row px-2">
    <div className="col-lg-3 col-md-6 pb-3 px-1">
      <RectShape color="#dadada" style={{ height: "200px" }} />
    </div>
    <div className="col-lg-3 col-md-6 pb-3 px-1">
      <RectShape color="#dadada" style={{ height: "200px" }} />
    </div>
    <div className="col-lg-3 col-md-6 pb-3 px-1">
      <RectShape color="#dadada" style={{ height: "200px" }} />
    </div>
    <div className="col-lg-3 col-md-6 pb-3 px-1">
      <RectShape color="#dadada" style={{ height: "200px" }} />
    </div>
    <div className="col-lg-3 col-md-6 pb-3 px-1">
      <RectShape color="#dadada" style={{ height: "200px" }} />
    </div>
    <div className="col-lg-3 col-md-6 pb-3 px-1">
      <RectShape color="#dadada" style={{ height: "200px" }} />
    </div>
    <div className="col-lg-3 col-md-6 pb-3 px-1">
      <RectShape color="#dadada" style={{ height: "200px" }} />
    </div>
    <div className="col-lg-3 col-md-6 pb-3 px-1">
      <RectShape color="#dadada" style={{ height: "200px" }} />
    </div>
    <div className="col-lg-3 col-md-6 pb-3 px-1">
      <RectShape color="#dadada" style={{ height: "200px" }} />
    </div>
    <div className="col-lg-3 col-md-6 pb-3 px-1">
      <RectShape color="#dadada" style={{ height: "200px" }} />
    </div>
    <div className="col-lg-3 col-md-6 pb-3 px-1">
      <RectShape color="#dadada" style={{ height: "200px" }} />
    </div>
    <div className="col-lg-3 col-md-6 pb-3 px-1">
      <RectShape color="#dadada" style={{ height: "200px" }} />
    </div>
  </div>
);

const InfiniteHits = ({ hits, load, hasMore, nextResult, videoType }) => {
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  const [checkInternet, setCheckInternet] = useState(
    window.navigator.onLine ? true : false
  );
  const [categoryNamesForSearchableItems, setCategoryNameForSearchableItems] =
    useState([]);

  useEffect(() => {
    let tempArrayForSavingSearchableItems = [];

    // check which heading corresponds with the searched
    for (let counter = 0; counter < hits.length; counter++) {
      //check for breaking loops
      let abortWhenFound = false;

      //making the value empty so there's no wrong values
      tempArrayForSavingSearchableItems[counter] = null;

      console.log("highlist", hits[counter]);

      // checking if it corresponds to the village name
      if (
        hits[counter]._highlightResult?.district_name?.matchLevel == "full" ||
        hits[counter]._highlightResult?.state_name?.matchLevel == "full" ||
        hits[counter]._highlightResult?.village_name?.matchLevel == "full"
      ) {
        tempArrayForSavingSearchableItems[counter] = "Culture";
        abortWhenFound = true;
      }

      //belief sub-counter
      for (
        let subCounter = 0;
        subCounter < hits[counter]?.data?.belief?.length && !abortWhenFound;
        subCounter++
      ) {
        if (
          hits[counter]._highlightResult?.data?.belief[subCounter]?.title
            ?.matchLevel == "full"
        ) {
          tempArrayForSavingSearchableItems[counter] = "Belief";
          abortWhenFound = true;
          break;
        } else if (
          hits[counter]._highlightResult?.data?.belief[subCounter]
            ?.belief_related_to?.matchLevel == "full"
        ) {
          tempArrayForSavingSearchableItems[counter] = "Belief";
          abortWhenFound = true;
          break;
        } else if (
          hits[counter]._highlightResult?.data?.belief[subCounter]?.description
            ?.matchLevel == "full"
        ) {
          tempArrayForSavingSearchableItems[counter] = "Belief";
          abortWhenFound = true;
          break;
        } else if (
          hits[counter]._highlightResult?.data?.belief[subCounter]
            ?.type_of_belief?.matchLevel == "full"
        ) {
          tempArrayForSavingSearchableItems[counter] = "Belief";
          abortWhenFound = true;
          break;
        }
      }

      //famous_personalities sub-counter
      for (
        let subCounter = 0;
        subCounter < hits[counter]?.data?.famous_personalities?.length &&
        !abortWhenFound;
        subCounter++
      ) {
        if (
          hits[counter]._highlightResult.data?.famous_personalities[subCounter]
            ?.name_of_local_hero?.matchLevel == "full"
        ) {
          tempArrayForSavingSearchableItems[counter] = "Famous Personalities";
          abortWhenFound = true;
          break;
        } else if (
          hits[counter]._highlightResult.data?.famous_personalities[subCounter]
            ?.family_related_to?.matchLevel == "full"
        ) {
          tempArrayForSavingSearchableItems[counter] = "Famous Personalities";
          abortWhenFound = true;
          break;
        } else if (
          hits[counter]._highlightResult.data?.famous_personalities[subCounter]
            ?.legend_stories?.matchLevel == "full"
        ) {
          tempArrayForSavingSearchableItems[counter] = "Famous Personalities";
          abortWhenFound = true;
          break;
        } else if (
          hits[counter]._highlightResult.data?.famous_personalities[subCounter]
            ?.personal_details?.matchLevel == "full"
        ) {
          tempArrayForSavingSearchableItems[counter] = "Famous Personalities";
          abortWhenFound = true;
          break;
        }
      }

      //festivals
      for (
        let subCounter = 0;
        subCounter < hits[counter]?.data?.festivals?.length && !abortWhenFound;
        subCounter++
      ) {
        if (
          hits[counter]._highlightResult?.data?.festivals[subCounter]?.title
            ?.matchLevel == "full"
        ) {
          tempArrayForSavingSearchableItems[counter] = "Festivals";
          abortWhenFound = true;
          break;
        } else if (
          hits[counter]._highlightResult?.data?.festivals[subCounter]
            ?.description?.matchLevel == "full"
        ) {
          tempArrayForSavingSearchableItems[counter] = "Festivals";
          abortWhenFound = true;
          break;
        } else if (
          hits[counter]._highlightResult?.data?.festivals[subCounter]
            ?.type_of_festival?.matchLevel == "full"
        ) {
          tempArrayForSavingSearchableItems[counter] = "Festivals";
          abortWhenFound = true;
          break;
        } else if (
          hits[counter]._highlightResult?.data?.festivals[subCounter]
            ?.who_organise_festival?.matchLevel == "full"
        ) {
          tempArrayForSavingSearchableItems[counter] = "Festivals";
          abortWhenFound = true;
          break;
        } else if (
          hits[counter]._highlightResult?.data?.festivals[subCounter]
            ?.who_take_part_in_festival?.matchLevel == "full"
        ) {
          tempArrayForSavingSearchableItems[counter] = "Festivals";
          abortWhenFound = true;
          break;
        }
      }

      //heritage_places
      for (
        let subCounter = 0;
        subCounter < hits[counter].data?.heritage_places?.length &&
        !abortWhenFound;
        subCounter++
      ) {
        if (
          hits[counter]._highlightResult?.data?.heritage_places[subCounter]
            .title?.matchLevel == "full"
        ) {
          tempArrayForSavingSearchableItems[counter] = "Heritage places";
          abortWhenFound = true;
          break;
        } else if (
          hits[counter]._highlightResult?.data?.heritage_places[subCounter]
            .description?.matchLevel == "full"
        ) {
          tempArrayForSavingSearchableItems[counter] = "Heritage places";
          abortWhenFound = true;
          break;
        } else if (
          hits[counter]._highlightResult?.data?.heritage_places[subCounter]
            .deities_related_to_heritage?.matchLevel == "full"
        ) {
          tempArrayForSavingSearchableItems[counter] = "Heritage places";
          abortWhenFound = true;
          break;
        } else if (
          hits[counter]._highlightResult?.data?.heritage_places[subCounter]
            .deities_related_to_heritage_dsr?.matchLevel == "full"
        ) {
          tempArrayForSavingSearchableItems[counter] = "Heritage places";
          abortWhenFound = true;
          break;
        } else if (
          hits[counter]._highlightResult?.data?.heritage_places[subCounter]
            .ritual_worship_related_to_heritage?.matchLevel == "full"
        ) {
          tempArrayForSavingSearchableItems[counter] = "Heritage places";
          abortWhenFound = true;
          break;
        } else if (
          hits[counter]._highlightResult?.data?.heritage_places[subCounter]
            .story_associate_with_heritage?.matchLevel == "full"
        ) {
          tempArrayForSavingSearchableItems[counter] = "Heritage places";
          abortWhenFound = true;
          break;
        } else if (
          hits[counter]._highlightResult?.data?.heritage_places[subCounter]
            .type_of_heritage?.matchLevel == "full"
        ) {
          tempArrayForSavingSearchableItems[counter] = "Heritage places";
          abortWhenFound = true;
          break;
        }
      }

      //ornaments
      for (
        let subCounter = 0;
        subCounter < hits[counter].data?.ornaments?.length && !abortWhenFound;
        subCounter++
      ) {
        if (
          hits[counter]._highlightResult.data?.ornaments[subCounter]?.title
            ?.matchLevel == "full"
        ) {
          tempArrayForSavingSearchableItems[counter] = "Ornaments";
          abortWhenFound = true;
          break;
        } else if (
          hits[counter]._highlightResult.data?.ornaments[subCounter]
            ?.description?.matchLevel == "full"
        ) {
          tempArrayForSavingSearchableItems[counter] = "Ornaments";
          abortWhenFound = true;
          break;
        }
      }

      //prominent_artists
      for (
        let subCounter = 0;
        subCounter < hits[counter].data?.prominent_artists?.length &&
        !abortWhenFound;
        subCounter++
      ) {
        if (
          hits[counter]._highlightResult.data?.prominent_artists[subCounter]
            .name?.matchLevel == "full"
        ) {
          tempArrayForSavingSearchableItems[counter] = "Prominent Artists";
          abortWhenFound = true;
          break;
        } else if (
          hits[counter]._highlightResult.data?.prominent_artists[subCounter]
            .address?.matchLevel == "full"
        ) {
          tempArrayForSavingSearchableItems[counter] = "Prominent Artists";
          abortWhenFound = true;
          break;
        } else if (
          hits[counter]._highlightResult.data?.prominent_artists[subCounter]
            .expertise_of_category?.matchLevel == "full"
        ) {
          tempArrayForSavingSearchableItems[counter] = "Prominent Artists";
          abortWhenFound = true;
          break;
        } else if (
          hits[counter]._highlightResult.data?.prominent_artists[subCounter]
            .expertise_of_skills?.matchLevel == "full"
        ) {
          tempArrayForSavingSearchableItems[counter] = "Prominent Artists";
          abortWhenFound = true;
          break;
        }
      }

      //traditional_art
      for (
        let subCounter = 0;
        subCounter < hits[counter].data?.traditional_art?.length &&
        !abortWhenFound;
        subCounter++
      ) {
        if (
          hits[counter]._highlightResult?.data?.traditional_art[subCounter]
            .name_of_art?.matchLevel == "full"
        ) {
          tempArrayForSavingSearchableItems[counter] = "Traditional Art";
          abortWhenFound = true;
          break;
        } else if (
          hits[counter]._highlightResult?.data?.traditional_art[subCounter]
            .art_from_pratice_in_village?.matchLevel == "full"
        ) {
          tempArrayForSavingSearchableItems[counter] = "Traditional Art";
          abortWhenFound = true;
          break;
        } else if (
          hits[counter]._highlightResult?.data?.traditional_art[subCounter]
            .classification_of_art_form?.matchLevel == "full"
        ) {
          tempArrayForSavingSearchableItems[counter] = "Traditional Art";
          abortWhenFound = true;
          break;
        } else if (
          hits[counter]._highlightResult?.data?.traditional_art[subCounter]
            .description?.matchLevel == "full"
        ) {
          tempArrayForSavingSearchableItems[counter] = "Traditional Art";
          abortWhenFound = true;
          break;
        } else if (
          hits[counter]._highlightResult?.data?.traditional_art[subCounter]
            .design_and_motifs?.matchLevel == "full"
        ) {
          tempArrayForSavingSearchableItems[counter] = "Traditional Art";
          abortWhenFound = true;
          break;
        } else if (
          hits[counter]._highlightResult?.data?.traditional_art[subCounter]
            .material_used?.matchLevel == "full"
        ) {
          tempArrayForSavingSearchableItems[counter] = "Traditional Art";
          abortWhenFound = true;
          break;
        } else if (
          hits[counter]._highlightResult?.data?.traditional_art[subCounter]
            .occasion_display_and_sale?.matchLevel == "full"
        ) {
          tempArrayForSavingSearchableItems[counter] = "Traditional Art";
          abortWhenFound = true;
          break;
        } else if (
          hits[counter]._highlightResult?.data?.traditional_art[subCounter]
            .practicing_communities?.matchLevel == "full"
        ) {
          tempArrayForSavingSearchableItems[counter] = "Traditional Art";
          abortWhenFound = true;
          break;
        } else if (
          hits[counter]._highlightResult?.data?.traditional_art[subCounter]
            .technique_used?.matchLevel == "full"
        ) {
          tempArrayForSavingSearchableItems[counter] = "Traditional Art";
          abortWhenFound = true;
          break;
        } else if (
          hits[counter]._highlightResult?.data?.traditional_art[subCounter]
            .type_of_art_craft?.matchLevel == "full"
        ) {
          tempArrayForSavingSearchableItems[counter] = "Traditional Art";
          abortWhenFound = true;
          break;
        }
      }

      //traditional_dress
      for (
        let subCounter = 0;
        subCounter < hits[counter]?.data?.traditional_dress?.length &&
        !abortWhenFound;
        subCounter++
      ) {
        if (
          hits[counter]._highlightResult?.data?.traditional_dress[subCounter]
            .title?.matchLevel == "full"
        ) {
          tempArrayForSavingSearchableItems[counter] = "Traditional Dress";
          abortWhenFound = true;
          break;
        } else if (
          hits[counter]._highlightResult?.data?.traditional_dress[subCounter]
            .description?.matchLevel == "full"
        ) {
          tempArrayForSavingSearchableItems[counter] = "Traditional Dress";
          abortWhenFound = true;
          break;
        }
      }

      // traditional_food;
      for (
        let subCounter = 0;
        subCounter < hits[counter]?.data?.traditional_food?.length &&
        !abortWhenFound;
        subCounter++
      ) {
        if (
          hits[counter]?._highlightResult?.data?.traditional_food[subCounter]
            ?.title?.matchLevel == "full"
        ) {
          tempArrayForSavingSearchableItems[counter] = "Traditional Food";
          abortWhenFound = true;
          break;
        } else if (
          hits[counter]?._highlightResult?.data?.traditional_food[subCounter]
            ?.description?.matchLevel == "full"
        ) {
          tempArrayForSavingSearchableItems[counter] = "Traditional Food";
          abortWhenFound = true;
          break;
        }
      }

      //culture sub-counter
      for (
        let subCounter = 0;
        subCounter < 1 && !abortWhenFound;
        subCounter++
      ) {
        if (
          hits[counter]._highlightResult?.data?.culture_profile
            ?.historic_incident?.matchLevel == "full"
        ) {
          tempArrayForSavingSearchableItems[counter] = "Culture";
          abortWhenFound = true;
          break;
        } else if (
          hits[counter]._highlightResult?.data?.culture_profile
            ?.village_unique_from_other_village?.matchLevel == "full"
        ) {
          tempArrayForSavingSearchableItems[counter] = "Culture";
          abortWhenFound = true;
          break;
        } else if (
          hits[counter]._highlightResult?.data?.culture_profile
            ?.your_village_famous_for?.matchLevel == "full"
        ) {
          tempArrayForSavingSearchableItems[counter] = "Culture";
          abortWhenFound = true;
          break;
        }
      }
    }

    setCategoryNameForSearchableItems(tempArrayForSavingSearchableItems);

    //changing value on window size
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, [hits]);

  return (
    <>
      {!load && hits?.length == 0 ? (
        <div className="text-center pt-5">Nothing Found</div>
      ) : (
        <ReactPlaceholder
          ready={hits.length > 0}
          customPlaceholder={awesomePlaceholder}
        >
          {hits.length > 0 ? (
            hits.map(
              (hit, index) =>
                hit?.video && (
                  <div
                    className={
                      "col-lg-3 col-md-6 pb-3  " +
                      (width < 768 ? "px-0" : "px-2")
                    }
                    key={index}
                  >
                    <VillageCard
                      village={hit}
                      name={hit?.name}
                      state={hit?.state_name}
                      cover={hit?.video}
                      categoryName={categoryNamesForSearchableItems[index]}
                      thumbnaiId={hit?.thumbnail_image_id}
                      isVillage={hit?.videoType === "village_video"}
                    />
                  </div>
                )
            )
          ) : (
            <Alert className="mx-6" key={"dark"} variant={"info"}>
              {checkInternet
                ? "Loading villages information"
                : "No villages Found"}
            </Alert>
          )}

          <>
            <div className="d-flex align-items-center justify-content-center mt-4">
              {!hasMore && (
                <button
                  className="load-more-button my-auto mb-3 mx-3"
                  disabled={hasMore}
                  onClick={() => {
                    nextResult();
                  }}
                >
                  LOAD MORE
                </button>
              )}
            </div>
          </>
        </ReactPlaceholder>
      )}
    </>
  );
};

// const CustomInfiniteHits = connectInfiniteHits(InfiniteHits);

const CustomInfiniteHits = ({ searchValue, videoType }) => {
  const [hits, setHits] = useState([]);
  const [page, setPage] = useState(1);
  const [load, setLoad] = useState(true);

  useEffect(() => {
    setLoad(true);
    setPage(1);

    axios
      .get(
        `https://asia-south1-mgmd-356213.cloudfunctions.net/videoElasticSearch?query=${searchValue}&video_type=${videoType}&page=0&restrict=${"true"}&hitsPerPage=200`
      )
      .then((res) => {
        const updatedHits = res.data.hits.map((hit) => ({
          ...hit,
          name:
            videoType === "village_video"
              ? hit.village_name
              : videoType === "block_video"
              ? hit.block_name
              : "",
        }));

        setHits(updatedHits);

        setLoad(false);
      });
  }, []);

  const nextResult = () => {
    axios
      .get(
        `https://asia-south1-mgmd-356213.cloudfunctions.net/videoElasticSearch?query=${searchValue}&video_type=${videoType}&page=${page}&restrict=${"true"}&hitsPerPage=200`
      )
      .then((res) => {
        const updatedHits = res.data.hits.map((hit) => ({
          ...hit,
          name:
            videoType === "village_video"
              ? hit.village_name
              : videoType === "block_video"
              ? hit.block_name
              : "",
        }));

        setHits([...hits, ...updatedHits]);
        if (res.data.hits?.length > 0) {
          setPage(page + 1);
        }
      });
  };
  return (
    <InfiniteHits
      hits={hits}
      load={load}
      searchValue={searchValue}
      nextResult={nextResult}
      videoType={videoType}
    />
  );
};

class Gallery extends Component {
  state = {
    searchWord: "",
    villageList: [],
    searchClicked: false,
    scrolling: false,
    filterCount: 0,
    filterCountFinal: 0,
    checkInternet: window.navigator.onLine ? true : false,
    currentIndexForPagination: 0,
    checkSearchableOrNot: false, //check for enabling algolia
    allVideosVillageFetched: false, //checking if the video villages are less than 16(default size)
    villageListLoader: false, // using a check to better UX with loader while searching
    allDistricts: [],
    allStates: [],
    allBlock: [],
    allVillages: [],
    selectedValueForVillageFilter: "",
    loadingFilters: false, //loader for when the districts and staes are being refined
    selectedValueForDistrictFilter: "", //district filter val
    selectedValueForStateFilter: "", // state filer val
    selectedValueForBlockFilter: "",
    queryForFilters: {}, // firstore query,
    nothingFound: false, //check for no village being found
    highlight: "",
    filterValue: 0,
    isEndOfList: false,
    videoType: "village_video",
    selectedToggle: "village",
  };

  constructor(props) {
    super(props);
    this.hitref = React.createRef();
    this.targetRef = React.createRef();
    this.handleScroll2 = this.handleScroll2.bind(this);
    this.timeout = 0;
  }

  componentDidMount = () => {
    this.setState({ currentIndexForPagination: 0 });
    document
      .querySelector(".th-90-height-scroll")
      .addEventListener("scroll", this.handleScroll2);
    window.addEventListener("scroll", this.handleScroll2);
    this.fetchVillage({});
    this.fetchStatesAndDistricts(); //init fetch districts and states
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.isEndOfList !== this.state.isEndOfList) {
      if (this.state.isEndOfList == true) {
        if (
          this.state.selectedValueForDistrictFilter != "" ||
          this.state.selectedValueForStateFilter != ""
        ) {
          this.handleNextWithFilters();
        } else {
          this.fetchNext();
        }
      }
    }
  }

  componentWillUnmount() {
    document
      .querySelector(".th-90-height-scroll ")
      .removeEventListener("scroll", this.handleScroll2);
    window.removeEventListener("scroll", this.handleScroll2);
  }

  handleScroll2(event) {
    const node = document.getElementById("buttonLoadMore");

    if (node) {
      const top = node.getBoundingClientRect().top;
      const isEndOfList = top >= 0 && top <= window.innerHeight;

      if (isEndOfList) {
        this.setState({ isEndOfList: true });
      } else {
        this.setState({ isEndOfList: false });
      }
    }
  }

  handleScroll(event) {
    if (window.scrollY === 0 && this.state.scrolling === true) {
      this.setState({ scrolling: false });
    } else if (window.scrollY !== 0 && this.state.scrolling !== true) {
      this.setState({ scrolling: true });
    }
  }

  onIntersection = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        console.log("Target is intersecting");
      }
    });
  };

  fetchVillage = () => {
    this.setState({ loading: true, nothingFound: false });

    let db = firebase.firestore();

    let query = db
      .collection("large_village_list")
      .where("status", "==", "UWEUS Approved");

    if (this.state.videoType !== "all") {
      query = query.where("video_type", "==", this.state.videoType);
    }

    query = query.orderBy("video").limit(16);

    //list to store villages
    let list = [];

    query
      .get()
      .then((doc) => {
        //looping over every value from firestore
        doc.forEach((docc) => {
          let data = docc.data();
          // storing items
          if (data["status"] === "UWEUS Approved") {
            list.push({
              key: docc.id,
              village_key:
                data["video_type"] === "village_video"
                  ? data["village_document_id"]
                  : docc.id,
              state_name: data["state_name"],
              name:
                data["video_type"] === "village_video"
                  ? data["village_name"]
                  : data["video_type"] === "block_video"
                  ? data["block_name"]
                  : data["village_name"] || data["block_name"],
              thumbnail: data["video"],
              thumbnaiId: data["thumbnail_image_id"],
              videoType: data["video_type"],
            });
          }
        });
        
        if (list.length < 16 && this.state.videoType === "all") {
          // If we're fetching all videos and we don't have enough, fetch more
          let remainingQuery = db
            .collection("large_village_list")
            .where("status", "==", "UWEUS Approved")
            .orderBy("video")
            .startAfter(doc.docs[doc.docs.length - 1])
            .limit(16 - list.length);

          return remainingQuery.get();
        } else {
          return Promise.resolve({ empty: true });
        }
      })
      .then((remainingDoc) => {
        if (!remainingDoc.empty) {
          remainingDoc.forEach((docc) => {
            let data = docc.data();
            if (data["status"] === "UWEUS Approved") {
              list.push({
                key: docc.id,
                village_key:
                  data["video_type"] === "village_video"
                    ? data["village_document_id"]
                    : docc.id,
                state_name: data["state_name"],
                name:
                  data["video_type"] === "village_video"
                    ? data["village_name"]
                    : data["video_type"] === "block_video"
                    ? data["block_name"]
                    : data["village_name"] || data["block_name"],
                thumbnail: data["video"],
                thumbnaiId: data["thumbnail_image_id"],
                videoType: data["video_type"],
              });
            }
          });
        }

        //update state
        this.setState({
          villageList: [...list],
          loading: false,
          currentIndexForPagination: list[list.length - 1]?.key,
          allVideosVillageFetched: list.length < 16,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false });
      });
  };

  fetchNext = () => {
    this.setState({ loading: true });
    let db = firebase.firestore();
    let list = [];
    //getting the current doc ref
    const lastDocRef = db
      .collection("large_village_list")
      .doc(this.state.currentIndexForPagination);

    if (this.state.allVideosVillageFetched) {
      this.setState({ loading: false });
      return;
    }

    lastDocRef
      .get()
      .then((lastDocFetched) => {
        //making a ref and query to db
        let docRef = db
          .collection("large_village_list")
          .orderBy("video")
          .where("status", "==", "UWEUS Approved");

        if (this.state.videoType !== "all") {
          docRef = docRef.where("video_type", "==", this.state.videoType);
        }

        docRef
          .startAfter(lastDocFetched)
          .limit(16)
          .get()
          .then((doc) => {
            //looping over every value from firestore
            doc.forEach((docc) => {
              let data = docc.data();
              // storing items
              if (data["status"] === "UWEUS Approved" && data.video) {
                list.push({
                  key: docc.id,
                  village_key:
                    this.state.videoType === "village_video"
                      ? docc.data()?.["village_document_id"]
                      : docc.id,
                  state_name: docc.data()?.["state_name"],
                  name:
                    data["video_type"] === "village_video"
                      ? data["village_name"]
                      : data["video_type"] === "block_video"
                      ? data["block_name"]
                      : data["village_name"] || data["block_name"],
                  thumbnail: docc.data()?.["video"],
                  thumbnaiId: docc.data()?.["thumbnail_image_id"],
                  videoType: docc.data()["video_type"],
                });
              }
            });

            const newList = [...this.state.villageList, ...list];
            //update state
            this.setState({
              villageList: newList,
              loading: false,
              currentIndexForPagination: newList[newList.length - 1]?.key, // last element of the array
              allVideosVillageFetched: list.length < 16,
            });
          })
          .catch((err) => {
            console.log(err);
            this.setState({ loading: false });
          });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false });
      });
  };

  onReset = () => {
    this.fetchVillage();
    this.setState({ searchWord: "" });
    this.setState({ searchClicked: false });
  };

  handleFilterCount = (_passedValue) => {
    this.setState({
      // checkSearchableOrNot: true, //change this to false in prod
      allVideosVillageFetched: false,
    });

    //if the user clicks on default value
    if (_passedValue == "see_all_categories") {
      //check if the value is already zero
      if (this.state.filterCountFinal != 0) {
        document.getElementsByClassName(
          "ais-ClearRefinements-button"
        )[0].innerHTML =
          "Clear Filter : (" + (this.state.filterCountFinal - 1) + ")";
        this.setState({ filterCountFinal: this.state.filterCountFinal - 1 });

        //check if the filter count becomes zero then
        if (this.state.filterCountFinal - 1 == 0)
          this.setState({
            checkSearchableOrNot: false,
            allVideosVillageFetched: false,
          });
      }
    } else if (this.state.filterCountFinal != 2) {
      // value cant be greater than 2
      document.getElementsByClassName(
        "ais-ClearRefinements-button"
      )[0].innerHTML =
        "Clear Filter : (" + (this.state.filterCountFinal + 1) + ")";
      this.setState({ filterCountFinal: this.state.filterCountFinal + 1 });
    }
  };

  handleClearFilters = () => {
    this.setState({
      filterCountFinal: 0,
      checkSearchableOrNot: false,
      allVideosVillageFetched: false,
      nothingFound: false,
      selectedValueForDistrictFilter: "",
      selectedValueForStateFilter: "",
      selectedValueForBlockFilter: "",
      selectedValueForVillageFilter: "",
      villageList: [],
      filterValue: 0,
    });

    this.fetchVillage();
    this.fetchStatesAndDistricts();
  };

  handleSearch = (e) => {
    if (this.timeout) clearTimeout(this.timeout);

    this.setState({ highlight: e.target.value });
    //setting the loader on when search val greatr than 2
    if (e.target.value.length > 2) {
      this.setState({
        villageListLoader: true,
      });

      //reset states and districts
      this.fetchStatesAndDistricts();
    }

    //checking if the value is empty or not
    if (e.target.value == "" || e.target.value.length < 4)
      this.setState({
        checkSearchableOrNot: false,
        allVideosVillageFetched: false,
      });
    else
      this.setState({
        checkSearchableOrNot: true,
        allVideosVillageFetched: false,
      });

    this.timeout = setTimeout(() => {
      this.setState({
        villageListLoader: false,
        nothingFound: false,
      });
    }, 1000);
  };

  //handler filter click
  handleFilterClicked = (_value) => {
    this.setState({
      loadingFilters: true,
    });
  };

  //get the states
  fetchStatesAndDistricts = () => {
    // innit sttaes
    this.setState({
      selectedValueForDistrictFilter: "",
      selectedValueForStateFilter: "",
      selectedValueForBlockFilter: "",
      nothingFound: false,
    });

    // get states``
    firebase
      .firestore()
      .collection("state_detail")
      .limit(100)
      .get()
      .then((states) => {
        const tempStates = [];
        states.forEach((state) => {
          const obj = {
            value: state.data().state_name,
          };
          tempStates.push(obj);
        });

        //get districts
        firebase
          .firestore()
          .collection("state_district_detail")
          .get()
          .then((districts) => {
            const tempDistricts = [];

            districts.docs.map((district) => {
              const obj = {
                value: district.data().district_name,
                state_name: district.data().state_name,
              };
              tempDistricts.push(obj);
            });

            // get block
            firebase
              .firestore()
              .collection("district_block_detail")
              .limit(500)
              .get()
              .then((querySnapShot) => {
                const tempBlock = [];
                querySnapShot.forEach((data) => {
                  const obj = {
                    value: data.data().block_name,
                  };
                  tempBlock.push(obj);
                });
                this.setState({
                  allBlock: tempBlock,
                });
                // console.log(tempBlock)
              })
              .catch((err) => {
                console.log("err fetching block_names-----", err);
              });

            //set to state
            this.setState({
              allStates: tempStates,
              allDistricts: tempDistricts,
            });
          });
        //get districts
      })
      .catch((err) => {
        console.log("err fetching states and districts: ", err);
      });
  };

  countFilter() {
    let ary = [];
    if (!!this.state.selectedValueForDistrictFilter) {
      ary.push(this.state.selectedValueForDistrictFilter);
    }
    if (!!this.state.selectedValueForStateFilter) {
      ary.push(this.state.selectedValueForStateFilter);
    }
    if (!!this.state.selectedValueForBlockFilter) {
      ary.push(this.state.selectedValueForBlockFilter);
    }

    this.setState({
      filterValue: ary.length,
    });
  }

  //handle filter change
  handleChangeFilter = async (_value, _filterToBeUpdated) => {
    this.setState({
      loading: true,
      checkSearchableOrNot: false,
      nothingFound: false,
      villageList: [],
      currentIndexForPagination: 0,
      allVideosVillageFetched: false,
    });

    if (_filterToBeUpdated === "Village") {
      let list = [];
      this.setState({ selectedValueForVillageFilter: _value });
      let dbQuery = firebase
        .firestore()
        .collection("large_village_list")
        .where("live", "==", true)
        .where("block_name", "==", this.state.selectedValueForBlockFilter)
        .where("village_name", "==", _value);

      if (this.state.videoType !== "all") {
        dbQuery.where("video_type", "==", this.state.videoType);
      }

      dbQuery
        .limit(16)
        .orderBy("video")
        .get()
        .then((foundVillages) => {
          foundVillages.forEach((docc) => {
            let data = docc?.data();
            // storing items
            if (data?.["status"] === "UWEUS Approved") {
              list.push({
                key: docc.id,
                village_key:
                  this.state.videoType === "village_video"
                    ? docc.data()?.["village_document_id"]
                    : docc.id,
                state_name: docc.data()?.["state_name"],
                name:
                  this.state.videoType === "village_video"
                    ? docc.data()?.["village_name"]
                    : docc.data()?.["block_name"],
                thumbnail: docc.data()?.["video"],
                thumbnaiId: docc.data()?.["thumbnail_image_id"],
                videoType: data.video_type,
              });
            }
          });

          if (list.length == 0) {
            this.setState({
              nothingFound: true,
            });
          }

          //assign villages to state
          this.setState({
            loading: false,
            currentIndexForPagination: list[list.length - 1]?.key,
            villageList: [...list],
          });
        });
    } else {
      const db = await firebase.firestore().collection("state_district_detail");
      const blockDb = await firebase
        .firestore()
        .collection("district_block_detail");
      //temp storage
      let states = [];
      let districts = [];
      let blocks = [];
      let fitlervalues = [];

      //updating the state and district list
      if (_filterToBeUpdated == "District") {
        //query

        // here bcz state is taking time to set`  `
        this.setState({ selectedValueForDistrictFilter: _value });
        db.where("district_name", "==", _value)
          .get()
          .then(async (docs) => {
            docs.forEach((doc) => {
              states.push({ value: doc.data().state_name });
            });

            await blockDb.get().then((doc) => {
              doc.forEach((dd) => {
                let ddd = dd.data();
                if (ddd["district_name"] == _value) {
                  blocks.push({
                    value: dd.data().block_name,
                  });
                }
              });
            });

            if (_value === "") {
              states.push({ value: this.state.selectedValueForStateFilter });
            }
            //removing duplicates
            states = states.filter(
              (v, i, a) => a.findIndex((v2) => v2.value == v.value) === i
            );
            blocks = blocks.filter(
              (v, i, a) => a.findIndex((v2) => v2.value == v.value) === i
            );
            //assigning to state
            this.setState({
              allStates: states,
              selectedValueForDistrictFilter: _value,
              allBlock: blocks,
            });
            this.countFilter();
          });
      } else if (_filterToBeUpdated == "State") {
        //query
        if (this.state.selectedValueForBlockFilter == "") {
          db.where("state_name", "==", _value)
            .get()
            .then(async (docs) => {
              docs.forEach((doc) => {
                districts.push({
                  value: doc.data().district_name,
                  state_name: doc.data().state_name,
                });
              });

              //removing duplicates
              districts = districts.filter(
                (v, i, a) => a.findIndex((v2) => v2.value == v.value) === i
              );
              // fetch block names as per district values
              let ary = districts?.map((i) => i.value);
              let index = 0;
              const batchSize = 10;

              while (index < ary.length) {
                const batch = ary.slice(index, index + batchSize);
                await blockDb
                  .where("district_name", "in", batch)
                  .get()
                  .then((doc) => {
                    doc.forEach((dd) => {
                      let ddd = dd?.data();
                      blocks.push({
                        value: ddd.block_name,
                      });
                    });
                  });

                index += batchSize;
              }

              //assigning to state
              this.setState({
                selectedValueForStateFilter: _value,
                allDistricts: districts,
                allBlock: blocks,
              });
              this.countFilter();
            });
        } else {
          this.setState({
            selectedValueForStateFilter: _value,
          });
        }
      } else if (_filterToBeUpdated == "Block") {
        //query
        const dbBlock = await firebase
          .firestore()
          .collection("district_block_detail");
        dbBlock
          .where("block_name", "==", _value)
          .limit(10)
          .get()
          .then(async (docs) => {
            await docs.forEach((doc) => {
              districts.push({
                value: doc.data().district_name,
                district_name: doc.data().district_name,
              });
            });

            //removing duplicates
            districts = districts.filter(
              (v, i, a) => a.findIndex((v2) => v2.value == v.value) === i
            );

            // fetch state as per district values
            let ary = districts?.map((i) => i.value);
            let index = 0;
            const batchSize = 10;

            while (index < ary.length) {
              const batch = ary.slice(index, index + batchSize);
              await db
                .where("district_name", "in", batch)
                .get()
                .then((doc) => {
                  doc.forEach((dd) => {
                    let ddd = dd?.data();
                    states.push({
                      value: ddd.state_name,
                    });
                  });
                });

              index += batchSize;
            }
            // //assigning to state
            this.setState({
              // allDistricts: districts,
              selectedValueForBlockFilter: _value,
              allStates: states,
            });
            this.countFilter();
            let arr = [];
            const querySnapShot = await firebase
              .firestore()
              .collection("large_village_list")
              .where("block_name", "==", _value)
              .get();
            if (!querySnapShot.empty) {
              querySnapShot.forEach((doc) =>
                arr.push({ value: doc.data().village_name })
              );
            }
            this.setState({ allVillages: arr });
          });
      }

      //updating the villages
      //temp storage
      const list = [];
      let query = firebase
        .firestore()
        .collection("large_village_list")
        .where("live", "==", true);

      if (this.state.videoType !== "all") {
        query = query.where("video_type", "==", this.state.videoType);
      }

      if (_filterToBeUpdated === "District" && _value) {
        query = query.where("district_name", "==", _value);
      }

      if (_filterToBeUpdated === "State" && _value) {
        query = query.where("state_name", "==", _value);
      }

      if (_filterToBeUpdated === "Block" && _value) {
        query = query.where("block_name", "==", _value);
      }

      if (
        this.state.selectedValueForDistrictFilter &&
        _filterToBeUpdated !== "District"
      ) {
        query = query.where(
          "district_name",
          "==",
          this.state.selectedValueForDistrictFilter
        );
      }

      if (
        this.state.selectedValueForStateFilter &&
        _filterToBeUpdated !== "State"
      ) {
        query = query.where(
          "state_name",
          "==",
          this.state.selectedValueForStateFilter
        );
      }

      if (
        this.state.selectedValueForBlockFilter &&
        _filterToBeUpdated !== "Block"
      ) {
        query = query.where(
          "block_name",
          "==",
          this.state.selectedValueForBlockFilter
        );
      }

      query = query.limit(16).orderBy("video");

      //fetch villages
      query.get().then((foundVillages) => {
        foundVillages.forEach((docc) => {
          let data = docc?.data();
          // storing items
          if (data?.["status"] === "UWEUS Approved") {
            list.push({
              key: docc.id,
              village_key:
                data.video_type === "village_video"
                  ? docc.data()?.["village_document_id"]
                  : docc.id,
              state_name: docc.data()?.["state_name"],
              name:
                data.video_type === "village_video"
                  ? docc.data()?.["village_name"]
                  : docc.data()?.["block_name"],
              thumbnail: docc.data()?.["video"],
              thumbnaiId: docc.data()?.["thumbnail_image_id"],
              videoType: data.video_type,
            });
          }
        });

        if (list.length == 0) {
          this.setState({
            nothingFound: true,
          });
        }

        //assign villages to state
        this.setState({
          loading: false,
          currentIndexForPagination: list[list.length - 1]?.key,
          queryForFilters: query,
          villageList: [...list],
        });
      });
    }
  };

  //next when filters are clicked
  handleNextWithFilters = () => {
    this.setState({ loading: true });
    let db = firebase.firestore();
    let list = [];

    //getting the current doc ref
    const lastDocRef = db
      .collection("large_village_list")
      .doc(this.state.currentIndexForPagination);

    lastDocRef
      .get()
      .then((lastDocFetched) => {
        // Create a new query that includes the videoType filter
        let query = this.state.queryForFilters
          .where("video_type", "==", this.state.videoType)
          .orderBy("video")
          .startAfter(lastDocFetched)
          .limit(16);

        query
          .get()
          .then((doc) => {
            //looping over every value from firestore
            doc.forEach((docc) => {
              let data = docc.data();
              // storing items
              if (data["status"] === "UWEUS Approved") {
                list.push({
                  key: docc.id,
                  village_key:
                    this.state.videoType === "village_video"
                      ? docc.data()?.["village_document_id"]
                      : docc.id,
                  state_name: docc.data()?.["state_name"],
                  name:
                    this.state.videoType === "village_video"
                      ? docc.data()?.["village_name"]
                      : docc.data()?.["block_name"],
                  thumbnail: docc.data()?.["video"],
                  thumbnaiId: docc.data()?.["thumbnail_image_id"],
                  videoType: this.state.videoType,
                });
              }
            });

            //concat old and new vals
            const newList = [...this.state.villageList, ...list];

            //update state
            this.setState({
              villageList: newList,
              loading: false,
              currentIndexForPagination: newList[newList.length - 1]?.key,
              allVideosVillageFetched: list.length < 16,
            });
          })
          .catch((error) => {
            console.error("Error fetching next batch of villages: ", error);
            this.setState({ loading: false });
          });
      })
      .catch((error) => {
        console.error("Error getting last document: ", error);
        this.setState({ loading: false });
      });
  };

  handleToggleChange = (value) => {
    const videoType =
      value === "village"
        ? "village_video"
        : value === "block"
        ? "block_video"
        : "all";
    this.setState(
      {
        selectedToggle: value,
        videoType: videoType,
        villageList: [],
        currentIndexForPagination: 0,
        allVideosVillageFetched: false,
      },
      () => {
        this.fetchVillage();
      }
    );
  };

  render() {
    return (
      <PageContainer
        keywords="Village Heritage Videos, Cultural Mapping Videos"
        title="MGMD Video Gallery | Explore India's Village Culture and Heritage"
        description="Watch informative videos about India's village heritage, cultural mapping projects, and traditional practices. Explore the visual representation of India's rich cultural history."
        canonical="https://mgmd.gov.in/video-gallery"
      >
        <MyLayout>
          <div className="col-md-12 custom-list">
            <div className="pb-3 search-content justify-content-between">
              <MyDesktop>
                <div style={divStyle}>
                  <img
                    src={VirtualWebBanner}
                    style={{ width: "62%", position: "absolute", zIndex: "1" }}
                  />
                  <Box
                    sx={{
                      position: "absolute",
                      zIndex: "2",
                      width: "30%",
                      top: "28%",
                      left: "11%",
                    }}
                  >
                    <Typography
                      variant="h4"
                      color="white"
                      sx={{
                        fontFamily: "Playfair Display, serif",
                      }}
                    >
                      Virtual Tour
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      mt={5}
                      color="white"
                      sx={{ fontFamily: "Inter, sans-serif", fontSize: "1rem" }}
                    >
                      Engaging audio-visuals have been created of selected
                      blocks & villages, featuring their distinct culture,
                      stories, landscapes, rich heritage, and daily life.
                      Explore videos of your choice and journey through the
                      living traditions.
                    </Typography>
                  </Box>
                  <Box
                    width="69%"
                    sx={{
                      position: "absolute",
                      right: "-10%",
                      overflow: "hidden",
                    }}
                  >
                    <img
                      height="100%"
                      width="100%"
                      style={{
                        objectFit: "contain",
                      }}
                      src={VirtualTourImage}
                    ></img>
                  </Box>
                </div>
                <div
                  className="col-md-3 col-xs-12"
                  style={{ margin: "0 auto" }}
                >
                  <div style={containerStyle}>
                    <div
                      style={{
                        ...buttonStyle,
                        ...(this.state.selectedToggle === "all"
                          ? selectedStyle
                          : unselectedStyle),
                      }}
                      onClick={() => this.handleToggleChange("all")}
                    >
                      All Videos
                    </div>
                    <div
                      style={{
                        ...buttonStyle,
                        ...(this.state.selectedToggle === "village"
                          ? selectedStyle
                          : unselectedStyle),
                      }}
                      onClick={() => this.handleToggleChange("village")}
                    >
                      Village Videos
                    </div>
                    <div
                      style={{
                        ...buttonStyle,
                        ...(this.state.selectedToggle === "block"
                          ? selectedStyle
                          : unselectedStyle),
                      }}
                      onClick={() => this.handleToggleChange("block")}
                    >
                      Block Videos
                    </div>
                  </div>
                </div>
                <div className="row col-12 g-0 mt-3">
                  <CustomSearchBox
                    _className=""
                    _onchangeFunc={this.handleSearch}
                    placeHolderText="Search By Village/Block Name"
                  />
                </div>
                <div className="text-disclaimer-with-edit py-2">
                  Register New Village, click
                  <Link
                    className="text-primary px-1"
                    to={
                      localStorage.getItem("user") ? "/add-village" : "/login"
                    }
                  >
                    here
                  </Link>
                </div>
              </MyDesktop>
              <MyMobile loading={this.state.loading}>
                {/* <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <h1 className="th-25 fw-700 th-fair-play text-success">
                    Mera Gaon Meri Dharohar
                  </h1>
                  <h1 className="th-20 fw-700">Virtual Tours</h1>
                  <p style={{ fontStyle: "italic", textAlign: "center" }}>
                    Engaging audio-visuals have been created of selected
                    villages, featuring their distinct culture, stories,
                    traditions, landscapes, rich heritage, and daily life.
                    Explore videos of your choice and visit the village profile
                    for deeper insights.
                  </p>
                </div> */}
                <div style={divMobStyle}>
                  <Box
                    width="100%"
                    sx={{
                      position: "absolute",
                      overflow: "hidden",
                      height: "60vh",
                    }}
                  >
                    <img
                      height="120%"
                      width="120%"
                      // style={{
                      //   objectFit: "contain",
                      // }}
                      src={MobVirtualTourImage}
                    ></img>
                  </Box>
                  <img
                    src={VirtualMobBanner}
                    style={{
                      width: "100%",
                      position: "absolute",
                      zIndex: "1",
                      bottom: "40px",
                    }}
                  />
                  <Box
                    sx={{
                      position: "absolute",
                      zIndex: "2",
                      width: "90%",
                      top: "65%",
                      left: "5%",
                    }}
                  >
                    <Typography variant="subtitle1" color="white">
                      Virtual Tour
                    </Typography>
                    <Typography variant="subtitle2" mt={5} color="white">
                      Engaging audio-visuals have been created of selected
                      blocks & villages, featuring their distinct culture,
                      stories, landscapes, rich heritage, and daily life.
                      Explore videos of your choice and journey through the
                      living traditions.
                    </Typography>
                  </Box>
                </div>
                <div
                  className="col-md-3 col-xs-12"
                  style={{ margin: "0 auto" }}
                >
                  <div style={containerStyle}>
                    <div
                      style={{
                        ...buttonStyle,
                        ...(this.state.selectedToggle === "village"
                          ? selectedStyle
                          : unselectedStyle),
                      }}
                      onClick={() => this.handleToggleChange("village")}
                    >
                      Village Videos
                    </div>
                    <div
                      style={{
                        ...buttonStyle,
                        ...(this.state.selectedToggle === "block"
                          ? selectedStyle
                          : unselectedStyle),
                      }}
                      onClick={() => this.handleToggleChange("block")}
                    >
                      Block Videos
                    </div>
                  </div>
                </div>
                <div className="row col-12 g-0 mt-3">
                  <CustomSearchBox
                    _className=""
                    _onchangeFunc={this.handleSearch}
                    placeHolderText="Search By Village/Block Name"
                  />
                </div>
                <div className="text-disclaimer-with-edit py-2">
                  Register New Village, click
                  <Link
                    className="text-primary px-1"
                    to={
                      localStorage.getItem("user") ? "/add-village" : "/login"
                    }
                  >
                    here
                  </Link>
                </div>
              </MyMobile>
            </div>
            <div className="drop-down-filter d-flex pb-3">
              <div className="filter-part">
                <div className="row">
                  <div className="col">
                    <MenuSelect
                      attribute="state_name"
                      f_name="State"
                      limit={100}
                      className="state_filter"
                      onClickFunc_={this.handleFilterCount}
                      passedDownItems={this.state.allStates}
                      onChangeFun_={this.handleChangeFilter}
                      selectedValue={this.state.selectedValueForStateFilter}
                    />
                  </div>
                  <div className="col">
                    <MenuSelect
                      attribute="district_name"
                      f_name="District"
                      limit={1000}
                      className="distric_filter"
                      onClickFunc_={this.handleFilterCount}
                      passedDownItems={this.state.allDistricts}
                      onChangeFun_={this.handleChangeFilter}
                      selectedValue={this.state.selectedValueForDistrictFilter}
                      isDisable={!this.state.selectedValueForStateFilter}
                    />
                  </div>
                  <div className="col">
                    <MenuSelect
                      attribute="tehsil"
                      f_name="Block"
                      limit={500}
                      className="block_filter"
                      onClickFunc_={this.handleFilterCount}
                      passedDownItems={this.state.allBlock}
                      onChangeFun_={this.handleChangeFilter}
                      selectedValue={this.state.selectedValueForBlockFilter}
                      isDisable={!this.state.selectedValueForDistrictFilter}
                    />
                  </div>
                  {this.state.videoType !== "block_video" && (
                    <>
                      <div className="col">
                        <MenuSelect
                          attribute="village_name"
                          f_name="Village"
                          limit={500}
                          className="block_filter"
                          onClickFunc_={this.handleFilterCount}
                          passedDownItems={this.state.allVillages}
                          onChangeFun_={this.handleChangeFilter}
                          selectedValue={
                            this.state.selectedValueForVillageFilter
                          }
                          isDisable={!this.state.selectedValueForBlockFilter}
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
              {this.state.filterValue > 0 ? (
                <div className="filter-clear-part">
                  <div
                    onClick={this.handleClearFilters}
                    className="cutom-btn ml-4 btn px-3 py-2 th-grey-2"
                  >
                    {/* {" "}{this.state.filterValue} */}
                    Clear
                  </div>
                </div>
              ) : (
                " "
              )}
            </div>
            <div
              className="th-village-height-scroll pr-md-3 pr-0"
              id="anchor"
              style={{ overflowX: "hidden" }}
            >
              {this.state.searchClicked &&
              this.state.searchWord.length !== 0 ? (
                <div className="pb-3 d-flex justify-content-between">
                  <div>
                    Search results for: "<b>{this.state.searchWord}</b>"
                  </div>
                  <div
                    onClick={this.onReset}
                    className="th-btn-green-outline th-fw-600 th-pointer px-3 ml-4"
                  >
                    Reset
                  </div>
                </div>
              ) : null}
              {this.state.searchClicked &&
              this.state.searchWord.length !== 0 ? null : (
                <div className="row"></div>
              )}

              <div className="row px-2 mobile-scroll">
                {!this.state.nothingFound ? (
                  <ReactPlaceholder
                    ready={
                      !this.state.villageListLoader &&
                      this.state.villageList.length > 0
                    }
                    customPlaceholder={awesomePlaceholder}
                  >
                    {
                      // this.state.villageList.length > 0 &&
                      !this.state.checkSearchableOrNot ? (
                        this.state.villageList.map((hit, index) => (
                          <div
                            className="col-lg-3 col-md-6 pb-3 px-1"
                            key={index}
                          >
                            <VillageCard
                              village={hit}
                              name={hit?.name}
                              state={hit?.state_name}
                              cover={hit?.thumbnail}
                              thumbnaiId={hit?.thumbnaiId}
                              isVillage={hit?.videoType === "village_video"}
                            />
                          </div>
                        ))
                      ) : (
                        <CustomInfiniteHits
                          searchValue={this.state.highlight}
                          videoType={this.state.videoType}
                        />
                      )
                    }

                    {/* load more will only show if the search is not done */}
                    {!this.state.checkSearchableOrNot &&
                    !this.state.allVideosVillageFetched ? (
                      <div
                        ref={this.targetRef}
                        id="buttonLoadMore"
                        className="d-block"
                        style={{
                          textAlign: "center",
                          position: "relative",
                          top: "-5px",
                        }}
                      >
                        <button
                          className="load-more-button my-auto mb-3 mx-3"
                          disabled={this.state.loading}
                          onClick={() => {
                            if (
                              this.state.selectedValueForDistrictFilter !==
                                "" ||
                              this.state.selectedValueForStateFilter !== "" ||
                              this.state.selectedValueForBlockFilter !== ""
                            ) {
                              this.handleNextWithFilters();
                            } else {
                              this.fetchNext();
                            }
                          }}
                        >
                          {this.state.loading ? "LOADING" : "LOAD MORE"}
                        </button>
                      </div>
                    ) : null}
                  </ReactPlaceholder>
                ) : (
                  <div className="text-center pt-5">Nothing Found</div>
                )}
              </div>
            </div>
          </div>
        </MyLayout>
      </PageContainer>
    );
  }
}

const divStyle = {
  height: "70vh", // Make sure the body takes the full viewport height
  width: "100%",
  position: "relative",
  left: "-5%",
};

const divMobStyle = {
  height: "100vh", // Make sure the body takes the full viewport height
  width: "100%",
  position: "relative",
  top: "-3%",
};

const containerStyle = {
  display: "flex",
  borderRadius: "20px",
  backgroundColor: "#ffffff",
  width: "350px",
  marginLeft: window.innerWidth > 768 ? "35%" : "6%",
  marginTop: window.innerWidth < 786 ? "10px" : "0",
};

const buttonStyle = {
  borderRadius: "20px",
  textTransform: "uppercase",
  fontWeight: "bold",
  fontSize: "0.75rem",
  padding: "8px 16px",
  margin: "0 2px",
  border: "none",
  outline: "none",
  cursor: "pointer",
  userSelect: "none",
  transition: "background-color 0.3s, color 0.3s",
};

const unselectedStyle = {
  backgroundColor: "#fff",
  color: "#757575",
};

const selectedStyle = {
  background: "linear-gradient(45deg, #4caf50 30%, #81c784 90%)",
  color: "#fff",
};

export default Gallery;
